@import '~@hagongyi/oc-mainsite-utilities/scss/index.scss';

@import '~@hagongyi/oc-mainsite-utilities/scss/components/oc-page-banner.scss';
@import '~@hagongyi/oc-mainsite-utilities/scss/components/oc-pagination.scss';
@import '~@hagongyi/oc-mainsite-utilities/scss/components/oc-article-nav.scss';

.oc-component__bread-nav {
  max-width: 1200px;
  .breadcrumb {
    margin-bottom: 0;
    background-color: #ffffff;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    .breadcrumb-item {
      &::before {
        width: 8px;
        height: 8px;
        background-size: 8px 8px;
        background-repeat: no-repeat;
        background-image: url('../../images/pages/common/icon-breadcrumb-divider.png');
        padding-right: 0;
        margin-right: 8px;
        margin-bottom: 1.5px;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .oc-component__bread-nav {
    font-size: 14px;
  }
}
