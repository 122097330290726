.information {
  &__more-button {
    display: block;
    width: 128px;
    color: #3F33A1;
    background-color: #ffffff;
    border: 1px solid #3F33A1;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    &:hover,
    &:active {
      color: #3F33A1;
      text-decoration: none;
    }
  }
  &__pdf {
    border: 1px solid #DDDDDD;
    margin-bottom: 40px;
    &-labels {
      display: flex;
      border-bottom: 1px solid #DDDDDD;
    }
    &-label {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      &:not(:last-of-type) {
        border-right: 1px solid #E9E9E9;
      }
      label {
        width: 100%;
        color: #444444;
        font-size: 1.25vw;
        text-align: center;
        line-height: 1;
        margin-bottom: 0;
        cursor: pointer;
      }
      input:focus + label,
      input:checked + label {
        color: #FF5080;
        font-weight: bold;
      }
    }
    &-tabs {
      margin-top: 30px;;
      margin-bottom: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
    &-tab {
      display: none;
      &-wrapper {
        display: flex;
      }
      &-item {
        width: calc((100% - (40px * 2)) / 3);
        text-decoration: none;
        background-color: #ffffff;
        border:1px solid #DDDDDD;
        border-radius: 32px 0px 32px 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        margin-right: 40px;
        margin-bottom: 20px;
        &:hover,
        &:active {
          text-decoration: none;
        }
        &-date {
          color: #3F33A1;
          font-size: 16px;
          line-height: 1;
          text-align: center;
          margin-bottom: 0;
          flex-shrink: 0;
          span {
            display: block;
          }
        }
        &-gap {
          width: 2px;
          height: 24px;
          background-color: #3F33A1;
          flex-shrink: 0;
          margin-left: 16px;
          margin-right: 16px;
        }
        &-title {
          flex: 1;
          color: #444444;
          font-size: 16px;
          line-height: 1;
          margin-bottom: 0;
        }
      }
    }
  }
  &__donate {
    border: 1px solid #DDDDDD;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-bottom: 40px;
    &-title {
      display: block;
    }
    &-content {
      &-text {
        font-size: 20px;
        line-height: 1;
        text-align: center;
      }
      &-money {
        color: #FF5080;
        font-size: 32px;
        line-height: 1;
        vertical-align: -3px;
        margin-left: 8px;
        margin-right: 8px;
      }
      &-list {
        margin-left: auto;
        margin-right: auto;
        margin-top: 32px;
        margin-bottom: 24px;
        border-collapse: collapse;
        thead {
          tr th {
            padding-left: 80px;
            padding-right: 80px;
            padding-bottom: 24px;
            text-align: center;
            font-size: 20px;
            line-height: 1;
          }
        }
        tbody {
          tr td {
            color: #666666;
            text-align: center;
            padding-bottom: 12px;
          }
        }
      }
    }
  }
  &__certificate {
    border: 1px solid #DDDDDD;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-bottom: 40px;
    &-title {
      display: block;
    }
    &-content {
      padding-left: 80px;
      padding-right: 80px;
      &-item {
        width: 360px;
        &-image {
          width: 100%;
          height: auto;
          margin-bottom: 16px;
        }
        &-text {
          font-size: 20px;
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .information {
    &__pdf {
      &-tab {
        &-item {
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .information {
    &__more-button {
      width: 100px;
      font-size: 12px;
    }
    &__pdf {
      margin-bottom: 24px;
      &-labels {
        flex-wrap: wrap;
      }
      &-label {
        width: calc(100% / 3);
        margin-top: 14px;
        margin-bottom: 14px;
        border-right: none !important;
        label {
          font-size: 12px;
        }
      }
      &-tabs {
        margin-top: 24px;;
        margin-bottom: 24px;
        padding-left: 16px;
        padding-right: 16px;
      }
      &-tab {
        &-item {
          width: 100%;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 14px;
          padding-right: 14px;
          margin-right: 0;
          &-date {
            font-size: 12px;
          }
          &-gap {
            height: 16px;
            margin-left: 12px;
            margin-right: 12px;
          }
          &-title {
            font-size: 14px;
          }
        }
      }
    }
    &__donate {
      padding-top: 24px;
      padding-bottom: 24px;
      &-content {
        &-text {
          font-size: 16px;
        }
        &-money {
          font-size: 24px;
          vertical-align: -3px;
        }
        &-list {
          margin-left: auto;
          margin-right: auto;
          margin-top: 16px;
          margin-bottom: 16px;
          thead {
            tr th {
              padding-left: 14px;
              padding-right: 14px;
              padding-bottom: 14px;
              font-size: 16px;
            }
          }
          tbody {
            tr td {
              font-size: 12px;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
    &__certificate {
      padding-top: 24px;
      padding-bottom: 24px;
      &-content {
        flex-wrap: wrap;
        padding-left: 16px;
        padding-right: 16px;
        &-item {
          width: 100%;
          &-text {
            font-size: 14px;
          }
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
