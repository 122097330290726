.hyc-component__project-resource-card {
  width: 328px;
  height: 100%;
  margin-left: 48px;

  &-title {
    width: 100%;
    box-sizing: border-box;
    padding: 23px 0;
    font-size: 20px;
    line-height: 28px;
    border-bottom: solid 2px #d5d5d5;

    &__icon {
      content: '';
      width: 28px;
      height: 28px;
      display: inline-block;
      vertical-align: top;
    }
    .icon-attachment{
      background: url("../../images/pages/project/project_outcome.png") no-repeat;
      background-size: 28px 28px;
    }
    .icon-video{
      background: url("../../images/pages/project/video_outcome.png") no-repeat;
      background-size: 28px 28px;
    }
  }

  &-item {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-around;
    padding: 19px 0 26px 0;
    border-bottom: solid 1px #e9e9e9;

    &__video-img{
      width: 8px;
      height: 10px;
      content: '';
      vertical-align: top;
      background: url("../../images/pages/project/video_play.png") no-repeat;
      background-size: 8px 10px;
      margin-top:5px;
    }

    &__img {
      width: 32px;
      height: 40px;
      margin-right: 16px;
      content: '';
      background-size: 32px 40px;
      //width: 100%;
      //height: 100%;
      vertical-align: top;
    }

    .resource-pdf {
      background: url("../../images/pages/project/pdf_attachment.png") no-repeat;
      background-size: 32px 40px;
    }

    .resource-video {
      background: url("../../images/pages/project/media_attachment.png") no-repeat;
      background-size: 32px 40px;
    }

    &__text {
      width: 161px;
      font-size: 12px;
      font-weight: 400;
      color: #222222;
    }

    &__time {
      color: #666666;
      margin-top: 5px;
    }

    &__content{
      width: 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: space-around;
      margin-top: 15px;
    }
  }

  &-video{
    &__item{
      width: 100%;
      margin-top: 20px;
    }
  }


  &-operations {
    width: 64px;

    .operation_btn {
      width: 64px;
      height: 24px;
      text-align: center;
      background: #e3e9f7;
      border-radius: 12px;
      color: #20477f;
      font-size: 12px;
      line-height: 24px;
      cursor: pointer;

      &__preview {
        margin-bottom: 10px
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .hyc-component__project-resource-card {
    width: 100%;
    height: 100%;
    //padding: 0 12.5px;
    margin-left: 0;

    &-title {
      width: 100%;
      box-sizing: border-box;
      padding: 17px 0;
      font-size: 16px;
      line-height: 24px;
      border-bottom: solid 2px #d5d5d5;

      &__icon {
        display: inline-block;
        content: '';
        width: 28px;
        height: 28px;
        vertical-align: top
      }
      .icon-attachment{
        background: url("../../images/pages/project/project_outcome.png") no-repeat;
        background-size: 28px 28px;
      }
      .icon-video{
        background: url("../../images/pages/project/video_outcome.png") no-repeat;
        background-size: 28px 28px;
      }
    }

    &-items {
      background-color: #f3f3f3;
      margin-top: 11.5px;
      margin-bottom: 20px;
    }

    &-item {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: space-around;
      padding-top: 12.5px;

      &__video-img{
        width: 8px;
        height: 10px;
        content: '';
        vertical-align: top;
        background: url("../../images/pages/project/video_play.png") no-repeat;
        background-size: 8px 10px;
        margin-top:5px;
      }

      &__img {
        width: 32px;
        height: 40px;
        margin-right: 8px;
        content: '';
        background-size: 32px 40px;
        //width: 100%;
        //height: 100%;
        vertical-align: top;
      }

      .resource-pdf {
        background: url("../../images/pages/project/pdf_attachment.png") no-repeat;
        background-size: 32px 40px;
      }

      .resource-video {
        background: url("../../images/pages/project/media_attachment.png") no-repeat;
        background-size: 32px 40px;
      }

      &__text {
        width: 200px;
        margin-left: -14px;
        font-size: 12px;
        font-weight: 400;
        color: #222222;
      }

      &__time {
        color: #666666;
        margin-top: 5px;
      }

      &__content{
        width: 100%;
        display: flex;
        box-sizing: border-box;
        justify-content: space-around;
        margin-top: 15px;
      }
    }

    &-video{
      &__item{
        width: 100%;
        margin-top: 20px;
      }
    }

    &-operations {
      width: 64px;

      .operation_btn {
        width: 64px;
        height: 24px;
        text-align: center;
        background: #e3e9f7;
        border-radius: 6px;
        border: solid 1px #2d5287;
        color: #20477f;
        font-size: 12px;
        line-height: 24px;

        &__preview {
          margin-bottom: 10px
        }
      }
    }
  }
}
