.news__list {
  margin-top: $hyc-news-list-page-margin;
  margin-bottom: $hyc-news-list-page-margin * 1.5;
  &-title {
    color: $hyc-color-news-list-title;
    font-size: $hyc-fontsize-news-list-heading;
    text-align: $hyc-news-list-title-text-align;
    font-weight: $hyc-news-list-title-font-weight;
    margin-bottom: $hyc-news-list-title-margin;

    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 9px;
      background-size: 14px 9px;
      background-repeat: no-repeat;
      background-image: url('../../../images/pages/common/icon-down.png');
      position: absolute;
      top: 100%;
      left: calc(50% - 7px);
      margin-top: 10px;
    }
  }
  &-content {
    &-timeline {
      border-left: 4px solid #F5F5F5;
      list-style: none;
      margin-left: 172px;
      padding-left: 0px;
    }
    &-card {
      padding-top: 16px;
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }
      &::before {
        display: block;
        position: absolute;
        content: attr(data-date);
        color: #444444;
        background-color: #F5F5F5;
        border-radius: 16px;
        font-size: 14px;
        line-height: 1;
        text-align: center;
        min-width: 140px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 12px;
        left: -176px;
      }
      &::after {
        display: block;
        position: absolute;
        content: "";
        box-shadow: 0 0 0 4px #3F33A1;
        background: #FFFFFF;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        left: -10px;
        top: 38px;
      }
      &-wrapper {
        display: flex;
        align-items: flex-start;
      }
      &-gap {
        display: block;
        border-top: 4px solid #F5F5F5;
        width: 30px;
        margin-left: 20px;
        margin-top: 28px;
        margin-right: 16px;
        flex-shrink: 0;
      }
      &-image {
        border: 4px solid #F5F5F5;
        width: 300px;
        height: auto;
        flex-shrink: 0;
      }
      &-content {
        width: 100%;
        min-width: 0;
        margin-top: 24px;
        margin-left: 30px;
        flex: 1;
        &-title {
          color: #333333;
          font-size: 24px;
          line-height: 1;
          margin-bottom: 20px;
        }
        &-text {
          color: #797A90;
          font-size: 14px;
          line-height: 24px;
          flex: 1;
          /* 限制两行 */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        &-link {
          display: block;
          background-color: #FF5080;
          color: #ffffff;
          font-size: 16px;
          text-align: center;
          line-height: 1;
          padding-top: 14px;
          padding-bottom: 14px;
          margin-left: auto;
          width: 124px;
          &:hover {
            color: #ffffff;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .news__list {
    margin-top: $hyc-news-list-page-margin - 16px; // 32px
    margin-bottom: $hyc-news-list-page-margin - 16px;
    &-title {
      margin-bottom: $hyc-news-list-title-margin / 2; // 30px
    }
    &-content {
      &-timeline {
        border-left: none;
        margin-left: 0;
        padding-left: 0px;
      }
      &-card {
        &::before,
        &::after {
          display: none;
        }
        &-image {
          border-width: 2px;
          width: 140px;
        }
        &-content {
          margin-top: 8px;
          margin-left: 8px;
          &-title {
            font-size: 14px;
            margin-bottom: 10px;
            /* 限制一行 */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &-text {
            font-size: 10px;
            line-height: 14px;
            margin-bottom: 10px;
            /* 限制两行 */
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          &-link {
            font-size: 10px;
            padding-top: 8px;
            padding-bottom: 8px;
            width: 64px;
          }
        }
      }
      .oc-component__pagination {
        &-link {
          font-size: 12px;
          padding-top: 8px;
          padding-bottom: 8px;
          min-width: 30px;
        }
        &-first,
        &-last {
          display: none;
        }
        &-first,
        &-last,
        &-prev,
        &-next {
          min-width: 64px;
        }
      }
    }
  }
}
