.project__history {
  &-content {
    &-section {
      &-title {
        font-size: 24px;
        color: $hyc-color-about-history-section-title;
        border: 1px solid $hyc-color-about-history-section-title;
        padding-top: $hyc-about-history-section-title-padding;
        padding-bottom: $hyc-about-history-section-title-padding;
        text-align: center;
        width: 148px;
      }
    }
    &-card {
      width: 31.58%;
      margin-right: 1.753%;
      background-color: #ffffff;
      &:nth-of-type(3n) {
        margin-right:0;
      }
      a {
        color: inherit;
        text-decoration: none;
        display: block;
      }
      .hyc-component__project-card {
        &-excerpt {
          text-align: center;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .project__history {
    &-content {
      &-section {
        &-title {
          font-size: 16px;
          width: 128px;
        }
      }
      &-card {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
