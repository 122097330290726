.hyc-component__project-card {
  width: 100%;
  &-image {
    display: block;
    width: 100%;
    height: auto;
  }
  &-title {
    font-size: $hyc-fontsize-project-title;
    text-align: center;
  }
  &-excerpt {
    color: #999999;
  }
}

@include media-breakpoint-down(md) {
  .hyc-component__project-card {
    &-title {
      font-size: $hyc-fontsize-project-title - 2px; // 16px
    }
  }
}
