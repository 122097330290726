.about__pages {
  background-image: url('../../../images/pages/common/hyc-background.png');
  background-size: $hyc-about-background-size;
  background-repeat: $hyc-about-background-repeat;
  margin-top: $hyc-about-page-margin;
  margin-bottom: $hyc-about-page-margin * 1.5;
  &-title {
    color: $hyc-color-about-title;
    font-size: $hyc-fontsize-about-heading;
    text-align: $hyc-about-title-text-align;
    font-weight: $hyc-about-title-font-weight;
    margin-bottom: $hyc-about-title-margin;

    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 9px;
      background-size: 14px 9px;
      background-repeat: no-repeat;
      background-image: url('../../../images/pages/about/icon-down.png');
      position: absolute;
      top: 100%;
      left: calc(50% - 7px);
      margin-top: 10px;
    }
  }
  &-content {
    color: $hyc-color-about-content;
    max-width: $hyc-about-content-max-width;
    & > p,
    & > h5 {
      text-indent: $hyc-about-content-text-indent;
    }
    p {
      font-size: $hyc-fontsize-about-normal;
    }
    & > ol > li {
      list-style-type: simp-chinese-informal;
      list-style-position: inside;
      & > ol > li {
        list-style-type: decimal !important;
        list-style-position: inside;
      }
    }
    @supports not (list-style-type: simp-chinese-informal) {
      & > ol > li {
        // list-style-type: cjk-ideographic;
        &:nth-of-type(1) {
          list-style-type: none;
          &::before {
            content: '一、';
          }
        }
        &:nth-of-type(2) {
          list-style-type: none;
          &::before {
            content: '二、';
          }
        }
        &:nth-of-type(3) {
          list-style-type: none;
          &::before {
            content: '三、';
          }
        }
        &:nth-of-type(4) {
          list-style-type: none;
          &::before {
            content: '四、';
          }
        }
        &:nth-of-type(5) {
          list-style-type: none;
          &::before {
            content: '五、';
          }
        }
        &:nth-of-type(6) {
          list-style-type: none;
          &::before {
            content: '六、';
          }
        }
        &:nth-of-type(7) {
          list-style-type: none;
          &::before {
            content: '七、';
          }
        }
        &:nth-of-type(8) {
          list-style-type: none;
          &::before {
            content: '八、';
          }
        }
        &:nth-of-type(9) {
          list-style-type: none;
          &::before {
            content: '九、';
          }
        }
        &:nth-of-type(10) {
          list-style-type: none;
          &::before {
            content: '十、';
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .about__pages {
    margin-top: $hyc-about-page-margin - 16px; // 32px
    margin-bottom: $hyc-about-page-margin - 16px;
    &-title {
      // font-size: $hyc-fontsize-about-heading - 6px; // 24px
      margin-bottom: $hyc-about-title-margin / 2; // 30px
    }
  }
}
