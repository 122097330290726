.footer {
  // 导航和联系方式
  &__infomation {
    padding-top: $hyc-footer-information-padding;
    padding-bottom: $hyc-footer-information-padding;
    background-color: $hyc-color-background-purple;

    // 页脚导航
    &-nav {
      &-root {
        margin-right: $hyc-footer-nav-root-margin;
        &:last-of-type {
          margin-right: 0;
        }
      }
      &-link {
        color: $hyc-color-footer-text;
        font-size: $hyc-fontsize-footer-small;
        &:hover {
          color: $light;
        }
      }
    }
    // 联系方式
    &-contact {
      width: 320px;
      // 联系信息
      &-info {
        &-item {
          margin-bottom: $hyc-footer-contact-item-margin;
        }
        &-img {
          width: $hyc-footer-icon-size;
          height: $hyc-footer-icon-size;
        }
        &-text {
          color: $hyc-color-footer-text;
          font-size: $hyc-fontsize-footer-normal;
          margin-left: $hyc-footer-contact-text-margin;
          &.flex-1 {
            flex: 1;
          }
        }
      }
      // 二维码
      &-qr-code {
        &-wrapper {
          width: $hyc-footer-contact-qr-size + 8px; // 130px
        }
        &-img {
          width: $hyc-footer-contact-qr-size + 8px;
        }
        &-text {
          font-size: $hyc-fontsize-footer-small;
        }
      }
    }

    &.english {
      @include media-breakpoint-down(md) {
        .footer__infomation-contact {
          border-top: none;
        }
      }
    }
  }
  // 版权声明和备案
  &__copyright {
    padding-top: $hyc-footer-copyright-padding;
    padding-bottom: $hyc-footer-copyright-padding;
    background-color: $hyc-color-background-dark;
  }
}

@include media-breakpoint-down(md) {
  .footer {
    &__infomation {
      padding-top: $hyc-footer-information-padding / 2; // 30px
      padding-bottom: $hyc-footer-information-padding / 2; // 30px
      &-contact {
        width: 100%;
        border-top: 2px solid rgba($color: #ffffff, $alpha: 0.16);
        // 联系信息
        &-info {
          margin-top: $hyc-footer-information-padding / 3; // 20px
          &-item {
            margin-left: $hyc-footer-contact-item-margin;
            margin-right: $hyc-footer-contact-item-margin;
          }
          &-img {
            width: $hyc-footer-icon-size - 8px;
            height: $hyc-footer-icon-size - 8px;
          }
          &-text {
            font-size: $hyc-fontsize-footer-small;
          }
        }
      }
    }
    // 版权声明和备案
    &__copyright {
      font-size: $hyc-fontsize-footer-small;
    }
  }
}
