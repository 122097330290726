.project__pages {
  background-image: url('../../../images/pages/common/hyc-background.png');
  background-size: $hyc-project-background-size;
  background-repeat: $hyc-project-background-repeat;
  margin-top: $hyc-project-page-margin;
  margin-bottom: $hyc-project-page-margin * 1.5;
  &-title {
    color: $hyc-color-project-title;
    font-size: $hyc-fontsize-project-heading;
    text-align: $hyc-project-title-text-align;
    font-weight: $hyc-project-title-font-weight;
  }
  &-desc{
    text-align: $hyc-project-title-text-align;
    margin-bottom: $hyc-project-title-margin;
    font-size: $hyc-fontsize-project-normal;
    font-weight: 400;
    color: #3F33A1;
    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 9px;
      background-size: 14px 9px;
      background-repeat: no-repeat;
      background-image: url('../../../images/pages/about/icon-down.png');
      position: absolute;
      top: 52%;
      left: calc(50% - 7px);
      margin-top: 10px;
    }
  }
  &-content {
    display: block;
  }
}

@include media-breakpoint-down(md) {
  .project__pages {
    margin-top: $hyc-project-page-margin - 16px; // 32px
    margin-bottom: $hyc-project-page-margin - 16px;
    &-title {
      font-size: $hyc-fontsize-project-heading - 6px; // 24px
    }
    &-desc{
      margin-bottom: $hyc-project-title-margin / 2 + 21px; // 51px, 实际效果要 -19px 为 32px
    }
  }
}
