.project__intro {
  &-section {
    display: block;
  }
  &-title {
    display: block;
  }
  &-content {
    &-card {
      width: 31.58%;
      margin-right: 1.753%;
      background-color: #FFFFFF;
      border: 2px solid #EBEAF5;
      &:nth-of-type(3n) {
        margin-right:0;
      }
      a {
        color: inherit;
        text-decoration: none;
        display: block;
      }
      .hyc-component__project-card {
        &-excerpt {
          text-align: justify;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .project__intro {
    &-content {
      &-card {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
