.about__found {
  &-content {
    & > p,
    & > h5 {
      line-height: $hyc-about-found-content-line-height;
      margin-bottom: $hyc-about-found-content-para-margin;
    }
  }
}

@include media-breakpoint-down(md) {
  .about__found {
    &-content {
      & > p,
      & > h5 {
        // line-height: $hyc-about-found-content-line-height - 10px; // 30px
        margin-bottom: $hyc-about-found-content-para-margin - 16px; // 24px
      }
    }
  }
}
