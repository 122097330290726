.video__list {
  margin-top: $hyc-video-list-page-margin;
  margin-bottom: $hyc-video-list-page-margin * 1.5;
  &-title {
    color: $hyc-color-video-list-title;
    font-size: $hyc-fontsize-video-list-heading;
    text-align: $hyc-video-list-title-text-align;
    font-weight: $hyc-video-list-title-font-weight;
    margin-bottom: $hyc-video-list-title-margin;

    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 9px;
      background-size: 14px 9px;
      background-repeat: no-repeat;
      background-image: url('../../../images/pages/common/icon-down.png');
      position: absolute;
      top: 100%;
      left: calc(50% - 7px);
      margin-top: 10px;
    }
  }
  &-content {
    &-videos {
      display: flex;
    }
    &-item {
      width: 23.423%;
      background-color: #ffffff;
      margin-bottom: 26px;
    }
    // 视频播放器 <video id="videoPlayer">
    &-video {
      width: 100%;
      height: auto;
      display: block;
      background-color: #EEEEEE;
      // 视频控制部分 <div id="videoControl">
      &-controls {
        display: flex;
        background-color: rgb(233,233,233,.6);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }
      // 播放按钮 <button>
      &-play {
        width: 60px;
        height: 60px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../../images/pages/video/icon-play.png');
        background-color: transparent;
        -webkit-appearance: none;
        border: none;
        padding: 0;
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
    &-box {
      // 视频播放容器 <figure id="videoContainer">
      &-video {
        display: block;
        position: relative;
        margin-bottom: 0;
      }
      // 视频标题和简介
      &-content {
        border: 1px solid #CCCCCC;
      }
    }
    &-title {
      color: #333333;
      font-size: 14px;
      text-overflow : ellipsis;
      border-bottom: 1px solid #CCCCCC;
      white-space : nowrap;
      margin-bottom: 0;
      margin-left: 16px;
      margin-right: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      overflow : hidden;
    }
    &-text {
      color: #666666;
      font-size: 12px;
      margin-bottom: 0;
      padding: 16px;
    }
  }
}

@include media-breakpoint-down(md) {
  .video__list {
    margin-top: $hyc-video-list-page-margin - 16px; // 32px
    margin-bottom: $hyc-video-list-page-margin - 16px;
    &-title {
      margin-bottom: $hyc-video-list-title-margin / 2; // 30px
    }
    &-content {
      &-item {
        width: 100%;
      }
      &-video {
        width: 100%;
        height: calc((100vw - 30px) * 0.657);
      }
      .oc-component__pagination {
        &-link {
          font-size: 12px;
          padding-top: 8px;
          padding-bottom: 8px;
          min-width: 30px;
        }
        &-first,
        &-last {
          display: none;
        }
        &-first,
        &-last,
        &-prev,
        &-next {
          min-width: 64px;
        }
      }
    }
  }
}
