.project-detail-container{
  display: flex;
}
@include media-breakpoint-down(sm) {
  .project-detail-container{
    display: block;
  }
}
.project__detail {
  display: block;
  &-content {
    color: $hyc-color-project-content;
    max-width: $hyc-project-content-max-width;
    // 保留富文本编辑器输出的样式
    // p {
    //   text-indent: $hyc-project-content-text-indent;
    // }
  }
}
