.header {
  &__logo {
    width: $hyc-header-logo-size;
  }
  &__navbar {
    @media (min-width: 75rem) {
      .container {
        max-width: 88.24vw !important;
      }
    }
    background-color: adjust-color(#ffffff, $alpha: -0.04);
    &-nav {
      &-root {
        margin-right: $hyc-header-navbar-nav-root-margin;
        &:last-of-type {
          margin-right: 0;
        }
        // Root 指上去展示下拉菜单
        &:hover {
          .dropdown-menu {
            display: block;
          }
        }
        // 更改默认链接颜色
        .nav-link {
          color: $hyc-color-header-text;
        }
        // 更改 Root 链接下面的下拉菜单
        .dropdown-menu {
          width: 100%;
          min-width: auto;
          border: none;
          border-top: 2px solid $hyc-color-header-dropdown-border;
          border-radius: 0;
          font-size: $hyc-fontsize-header-small;
          padding: 0;
          margin: 0;
        }
      }
      // 更改 Sub 和下拉菜单项目的样式
      &-root.dropdown-menu.dropdown-item,
      &-sub {
        width: auto;
        color: $hyc-color-header-text-sub;
        padding-top: $hyc-header-navbar-nav-sub-padding;
        padding-bottom: $hyc-header-navbar-nav-sub-padding;
        padding-left: 0;
        padding-right: 0;
        margin-left: $hyc-header-navbar-nav-sub-margin;
        margin-right: $hyc-header-navbar-nav-sub-margin;
        text-align: center;
        border-bottom: 1px solid $hyc-color-header-dropdown-item-border;
        &:last-of-type {
          border-bottom: none;
        }
        &:hover {
          color: $hyc-color-header-text-active;
          background-color: initial;
        }
      }
      // 主项目或者子项目活动时变为主题色粉色
      &-root,
      &-sub {
        &.active {
          .nav-link {
            color: $hyc-color-header-text-active;
            background-color: initial;
          }
          color: $hyc-color-header-text-active;
          background-color: initial;
        }
      }
      // &.mobile {
      //   height: calc(100% - 50px);
      // }
    }
    &-toggler {
      &:focus {
        outline: none;
      }
      &-icon {
        background-image: url('../../images/layouts/header/icon-navbar-toggler.png');
      }
    }
    &-collapse {
      position: relative;
    }
    &-language-toggler {
      color: #ff5080;
      border: 1px solid #ff5080;
      border-radius: 4px;
      padding: 8px;
      text-align: center;
      font-size: 12px;
      line-height: 1;
      text-decoration: none;
      margin: 0;
      display: inline-block;
      &:hover,
      &:active {
        color: #ff5080;
        text-decoration: none;
      }

      &-wrapper {
        justify-content: flex-end;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    &.english {
      .header__navbar-nav-sub {
        white-space: normal;
      }
    }
    // 重置 Bootstrap 默认样式
    &.navbar-expand-lg .navbar-nav .nav-link {
      padding-left: $hyc-header-navbar-nav-root-padding;
      padding-right: $hyc-header-navbar-nav-root-padding;
    }
    &.english.navbar-expand-lg .navbar-nav .nav-link {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  &__toolBar {
    display: block;
    @include media-breakpoint-down(md) {
      display: none !important;
    }
    &-wrapper {
      justify-content: flex-end;
    }
    &-search {
      display: flex;
      border: 1px solid #e0e0e0;
      &-input {
        display: inline-block;
        width: 200px;
        height: 34px;
        background-color: #f3f3f3;
        border: none;
        &:focus {
          outline: none;
        }
      }
      &-button {
        width: 34px;
        height: 34px;
        background-size: 100% 100%;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-image: url('../../images/layouts/header/icon-search-bar-search.png');
        border: none;
        padding: 0;
        &:hover {
          background-image: url('../../images/layouts/header/icon-search-bar-search-hover.png');
        }
        &:focus {
          outline: none;
        }
      }
    }
    &-wechat {
      width: 36px;
      height: 36px;
      background-size: 100% 100%;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-image: url('../../images/layouts/header/icon-search-bar-wechat.png');
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      position: relative;
      cursor: pointer;
      &:hover {
        background-image: url('../../images/layouts/header/icon-search-bar-wechat-hover.png');
        .header__toolBar-wechat-content {
          display: flex;
        }
      }
      &-content {
        display: none;
        flex-direction: row;
        flex-wrap: nowrap;
        background-color: #ffffff;
        z-index: 100;
        position: absolute;
        top: 120%;
        left: -66px;
      }
      &-item {
        background-color: #ffffff;
        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
      &-image {
        display: block;
        width: 80px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }
      &-text {
        font-size: 10px;
        line-height: 1;
        text-align: center;
        margin-bottom: 0;
      }
    }
    &-language {
      display: block;
      color: #bbbbbb;
      background-color: #ffffff;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      font-size: 14px;
      line-height: 34px;
      text-align: center;
      text-decoration: none;
      padding-left: 10px;
      padding-right: 10px;
      &:hover,
      &:active {
        color: $hyc-color-primary;
        text-decoration: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header__navbar {
    .container {
      flex-wrap: nowrap;
    }
    &-collapse {
      width: 80vw;
      height: calc(100vh - 84px);
      background-color: #ffffff;
      overflow: auto;
      position: fixed;
      top: 84px;
      bottom: 0;
      z-index: $zindex-fixed;
      // Fix collapse not work on iOS Safari: https://github.com/twbs/bootstrap/issues/16213
      cursor: pointer;
      // 使 Bootstrap 4 的 Collapse 变为横向的，从右向左
      &.collapse {
        right: -200vw;
        display: block;
        @include transition($hyc-header-navbar-transition-collapse-horizontal);
        &.show {
          right: 0;
        }
      }
      &.collapsing {
        right: 0;
        height: 100% !important;
        overflow: hidden;
        @include transition($hyc-header-navbar-transition-collapse-horizontal);
      }
    }
    &-nav {
      // padding-top: 16px;
      // padding-bottom: 16px;
      // padding-left: 16px;
      // padding-right: 16px;
      &-root {
        margin-right: 0;
        // 更改 Root 链接下面的下拉菜单
        .dropdown-menu {
          width: 100%;
          min-width: auto;
          border: none;
          border-top: none;
          border-radius: 0;
          font-size: $hyc-fontsize-header-small;
          padding: 0;
          margin: 0;
        }
      }
      // 更改 Sub 和下拉菜单项目的样式
      &-root.dropdown-menu.dropdown-item,
      &-sub {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 38px;
        margin-left: 0;
        margin-right: 0;
        text-align: left;
        border-bottom: none;
      }
    }
  }
}

