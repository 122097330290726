.donate__list {
  background-image: url('../../../images/pages/common/hyc-background.png');
  background-size: $hyc-donate-background-size;
  background-repeat: $hyc-donate-background-repeat;
  margin-top: $hyc-donate-page-margin;
  margin-bottom: $hyc-donate-page-margin * 1.5;
  &-title {
    color: $hyc-color-donate-title;
    font-size: $hyc-fontsize-donate-heading;
    text-align: $hyc-donate-title-text-align;
    font-weight: $hyc-donate-title-font-weight;
    margin-bottom: $hyc-donate-title-margin;

    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 9px;
      background-size: 14px 9px;
      background-repeat: no-repeat;
      background-image: url('../../../images/pages/common/icon-down.png');
      position: absolute;
      top: 100%;
      left: calc(50% - 7px);
      margin-top: 10px;
    }
  }
  &-content {
    &-text {
      font-size: 20px;
      line-height: 1;
      text-align: center;
    }
    &-money {
      color: #FF5080;
      font-size: 32px;
      line-height: 1;
      vertical-align: -3px;
      margin-left: 8px;
      margin-right: 8px;
    }
    &-form {
      width: 540px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 36px;
      padding-right: 36px;
      background-color: #E4E2F2;
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 32px;
      margin-bottom: 32px;
      &-title {
        color: #3F33A1;
        text-align: center;
      }
      &-text {
        color: #6c757d;
        text-align: center;
        font-size: 12px;
      }
      &-input {
        display: block;
        font-size: 14px;
        text-align: center;
        line-height: 1;
        background-color: #ffffff;
        border: 1px solid #FF5080;
        border-radius:16px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 100%;
        margin-bottom: 14px;
        &:focus {
          outline: none;
          outline-offset: 0;
        }
      }
      &-button {
        display: block;
        width: 84px;
        color: #ffffff;
        background-color: #3F33A1;
        border: none;
        border-radius: 4px;
        text-align: center;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-left: auto;
        margin-right: auto;
        &:focus {
          outline: none;
          outline-offset: 0;
        }
      }
    }
    &-result {
      display: block;
      &-info {
        color: #999999;
        font-size: 20px;
        text-align: center;
        margin-bottom: 32px;
      }
      &-list {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;
        border-collapse: collapse;
        thead {
          tr th {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 24px;
            text-align: center;
            font-size: 20px;
            line-height: 1;
          }
        }
        tbody {
          tr td {
            color: #666666;
            text-align: center;
            padding-bottom: 12px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .donate__list {
    margin-top: $hyc-donate-page-margin - 16px; // 32px
    margin-bottom: $hyc-donate-page-margin - 16px;
    &-title {
      margin-bottom: $hyc-donate-title-margin / 2; // 30px
    }
    &-content {
      &-text {
        font-size: 16px;
      }
      &-money {
        font-size: 24px;
        vertical-align: -3px;
      }
      &-form {
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
        &-input {
          font-size: 14px;
          width: 100%;
        }
        &-button {
          width: 72px;
          font-size: 12px;
        }
      }
      &-result {
        display: block;
        &-info {
          font-size: 16px;
          margin-bottom: 24px;
        }
        &-list {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 16px;
          thead {
            tr th {
              padding-left: 14px;
              padding-right: 14px;
              padding-bottom: 14px;
              font-size: 16px;
            }
          }
          tbody {
            tr td {
              font-size: 12px;
              padding-bottom: 10px;
            }
          }
        }
        .oc-component__pagination {
          &-link {
            font-size: 12px;
            padding-top: 8px;
            padding-bottom: 8px;
            min-width: 30px;
          }
          &-first,
          &-last {
            display: none;
          }
          &-first,
          &-last,
          &-prev,
          &-next {
            min-width: 64px;
          }
        }
      }
    }
  }
}
