.about__scope {
  &-content {
    & > p,
    & > h5 {
      line-height: $hyc-about-scope-content-line-height;
      margin-bottom: $hyc-about-scope-content-para-margin;
    }
    & > ol > li {
      font-size: 1.25rem;
      & > ol > li {
        font-size: 1rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .about__scope {
    &-content {
      & > p,
      & > h5 {
        margin-bottom: $hyc-about-found-content-para-margin - 16px; // 24px
      }
      & > ol {
        padding-left: 0;
        & > li:nth-of-type(3) {
          list-style-type: none;
          &::before {
            content: '' !important;
          }
          & > h5::before {
            content: '三、';
          }
        }
      }
    }
  }
}
