.information__list {
  margin-top: 48px;
  &-content {
    display: flex;
    &-item {
      width: calc((100% - (40px * 2)) / 3);
      text-decoration: none;
      background-color: #ffffff;
      border:1px solid #DDDDDD;
      border-radius: 32px 0px 32px 0px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      margin-right: 40px;
      margin-bottom: 20px;
      &:hover,
      &:active {
        text-decoration: none;
      }
      &-date {
        color: #3F33A1;
        font-size: 16px;
        line-height: 1;
        text-align: center;
        margin-bottom: 0;
        flex-shrink: 0;
        span {
          display: block;
        }
      }
      &-gap {
        width: 2px;
        height: 24px;
        background-color: #3F33A1;
        flex-shrink: 0;
        margin-left: 16px;
        margin-right: 16px;
      }
      &-title {
        flex: 1;
        color: #444444;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .information__list {
    &-content {
      &-item {
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .information__list {
    margin-top: 32px;
    &-content {
      flex-wrap: wrap;
      padding-left: 15px;
      padding-right: 15px;
      &-item {
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 14px;
        padding-right: 14px;
        margin-right: 0;
        margin-bottom: 16px;
        &-date {
          font-size: 12px;
        }
        &-gap {
          height: 16px;
          margin-left: 12px;
          margin-right: 12px;
        }
        &-title {
          font-size: 14px;
        }
      }
    }
  }
}
