//#region 公共颜色
$hyc-color-primary: #FF5080 !default;
$hyc-color-background-purple: #191C36 !default;
$hyc-color-background-dark: #02040A !default;
//#endregion

//#region 模块颜色
$hyc-color-header-text: #222222 !default;
$hyc-color-header-text-active: $hyc-color-primary !default;
$hyc-color-header-text-sub: #BCBCC3 !default;
$hyc-color-header-dropdown-border: $hyc-color-primary !default;
$hyc-color-header-dropdown-item-border: #E9E9E9 !default;
$hyc-color-header-collapse-mask-shadow: rgba(0, 0, 0, 0.4) !default;
$hyc-color-footer-text: #8C8E9B !default;
// 关于页面
$hyc-color-about-title: #363636 !default;
$hyc-color-about-content: #797A90 !default;
$hyc-color-about-supervisors-border: #DDDDDD !default;
$hyc-color-about-supervisors-name: #202146 !default;
$hyc-color-about-supervisors-gender: #B7C2D5 !default;
$hyc-color-about-supervisors-content: $hyc-color-about-content !default;
$hyc-color-about-supervisors-link: $hyc-color-about-supervisors-name !default;
$hyc-color-about-history-section-title: $hyc-color-primary !default;
// 项目介绍
$hyc-color-project-title: #363636 !default;
$hyc-color-project-content: #797A90 !default;
// 新闻动态
$hyc-color-news-list-title: #363636 !default;
// 视频列表
$hyc-color-video-list-title: #363636 !default;
// 合作伙伴
$hyc-color-partner-title: #363636 !default;
// 信息公开
$hyc-color-information-title: #363636 !default;
$hyc-color-information-certificate-title: #363636 !default;
// 捐赠列表
$hyc-color-donate-title: #363636 !default;
//#endregion

//#region 模块字号
$hyc-fontsize-header-small: 14px !default;
$hyc-fontsize-footer-normal: 16px !default;
$hyc-fontsize-footer-small: 14px !default;
// 关于页面
$hyc-fontsize-about-heading: 30px !default;
$hyc-fontsize-about-normal: 16px !default;
$hyc-fontsize-about-supervisors: 12px !default;
// 项目介绍
$hyc-fontsize-project-heading: 30px !default;
$hyc-fontsize-project-normal: 16px !default;
$hyc-fontsize-project-title: 18px !default;
// 新闻动态
$hyc-fontsize-news-list-heading: 30px !default;
// 视频列表
$hyc-fontsize-video-list-heading: 30px !default;
// 合作伙伴
$hyc-fontsize-partner-heading: 30px !default;
// 信息公开
$hyc-fontsize-information-heading: 30px !default;
$hyc-fontsize-information-certificate-heading: 30px !default;
// 捐赠列表
$hyc-fontsize-donate-heading: 30px !default;
//#endregion

//#region 模块透明度
$hyc-opacity-header-nav-bar: 0.96 !default;
//#endregion

//#region 页首导航
$hyc-header-logo-size: 230px !default;
$hyc-header-navbar-nav-root-margin: 6px !default;
$hyc-header-navbar-nav-root-padding: 28px !default;
$hyc-header-navbar-nav-sub-margin: 8px !default;
$hyc-header-navbar-nav-sub-padding: 14px !default;
$hyc-header-navbar-transition-collapse-horizontal: right 0.25s ease !default;
//#endregion

//#region 页脚导航和联系方式
$hyc-footer-information-padding: 60px !default;
$hyc-footer-contact-item-margin: 14px !default;
$hyc-footer-contact-text-margin: 14px !default;
$hyc-footer-contact-qr-size: 122px !default;
$hyc-footer-nav-root-margin: 56px !default;
$hyc-footer-icon-size: 24px !default;
// 页脚版权声明和备案
$hyc-footer-copyright-padding: 26px !default;
//#endregion

//#region 关于我们
$hyc-about-title-text-align: center !default;
$hyc-about-title-font-weight: normal !default;
$hyc-about-title-margin: 60px !default;
$hyc-about-content-max-width: 940px !default;
$hyc-about-content-text-indent: 2em !default;
$hyc-about-background-size: 100% !default;
$hyc-about-background-repeat: repeat-y !default;
$hyc-about-page-margin: 48px !default;
$hyc-about-found-content-line-height: 40px !default;
$hyc-about-scope-content-line-height: 40px !default;
$hyc-about-regulate-content-line-height: 32px !default;
$hyc-about-found-content-para-margin: 40px !default;
$hyc-about-scope-content-para-margin: 40px !default;
$hyc-about-supervisors-content-line-height: 24px !default;
$hyc-about-supervisors-card-margin: 40px !default;
$hyc-about-history-section-title-padding: 8px !default;
//#endregion

//#region 项目介绍
$hyc-project-background-size: 100% !default;
$hyc-project-background-repeat: repeat-y !default;
$hyc-project-page-margin: 48px !default;
$hyc-project-title-text-align: center !default;
$hyc-project-title-font-weight: normal !default;
$hyc-project-title-margin: 60px !default;
$hyc-project-content-max-width: 940px !default;
$hyc-project-content-text-indent: 2em !default;
//#endregion

//#region 新闻动态
$hyc-news-list-title-text-align: center !default;
$hyc-news-list-title-font-weight: normal !default;
$hyc-news-list-title-margin: 60px !default;
$hyc-news-list-page-margin: 48px !default;
//#endregion

//#region 视频列表
$hyc-video-list-title-text-align: center !default;
$hyc-video-list-title-font-weight: normal !default;
$hyc-video-list-title-margin: 60px !default;
$hyc-video-list-page-margin: 48px !default;
//#endregion

//#region 合作伙伴
$hyc-partner-title-text-align: center !default;
$hyc-partner-title-font-weight: normal !default;
$hyc-partner-title-margin: 60px !default;
$hyc-partner-page-margin: 48px !default;
$hyc-partner-background-size: 100% !default;
$hyc-partner-background-repeat: repeat-y !default;
//#endregion

//#region 信息公开
$hyc-information-title-text-align: center !default;
$hyc-information-title-font-weight: normal !default;
$hyc-information-title-margin: 60px !default;
$hyc-information-page-margin: 48px !default;
$hyc-information-background-size: 100% !default;
$hyc-information-background-repeat: repeat-y !default;
$hyc-information-certificate-title-text-align: center !default;
$hyc-information-certificate-title-font-weight: normal !default;
$hyc-information-certificate-title-margin: 60px !default;
$hyc-information-certificate-page-margin: 48px !default;
//#endregion

//#region 捐赠列表
$hyc-donate-background-size: 100% !default;
$hyc-donate-background-repeat: repeat-y !default;
$hyc-donate-title-text-align: center !default;
$hyc-donate-title-font-weight: normal !default;
$hyc-donate-title-margin: 60px !default;
$hyc-donate-page-margin: 48px !default;
//#endregion 捐赠列表

//#region 首页
$hyc-index-transition-project: bottom 0.20s ease !default;
//#endregion

// 更改 Bootstrap 配置
$breadcrumb-divider: '';
// $theme-colors: (
//   "primary": $hyc-color-primary,
// )
