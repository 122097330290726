.news__detail {
  &-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  &-date {
    color: #797A90;
    &::before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      background-size: 100%;
      background-image: url('../../../images/pages/news/icon-news-article-date.png');
      margin-right: 6px;
      vertical-align: -2px;
    }
  }
  &-content {
    img {
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .news__detail {
    &-title {
      font-size: 28px;
    }
    &-content {
      img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }
  .oc-component__article-nav {
    &-item {
      padding-left: 16px;
    }
    &-next {
      padding-top: 0;
    }
    &-link {
      font-size: 14px;
    }
  }
}
