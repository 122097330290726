.index {
  &__banner {
    width: 100vw;
    @include media-breakpoint-up(lg) {
      position: relative;
      // top: -86px;
      // margin-bottom: -86px;
    }
    &-container {
      width: 100%;
    }
    &-slide {
      background-color: #ffffff;
      &-link {
        display: block;
      }
      &-image {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  &__project {
    @media (min-width: 75rem) {
      .container {
        max-width: 88.24vw !important;
      }
    }
    padding-top: 56px;
    padding-bottom: 72px;
    background-image: url('../../../images/pages/index/hyc-project-background.png');
    background-color: #ebeaf5;
    background-size: auto 100%;
    background-repeat: repeat-y;
    background-position: center;
    @include media-breakpoint-down(md) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    &-title {
      color: #ff5080;
      font-size: 30px;
      line-height: 1;
      font-weight: normal;
      margin-bottom: 32px;
      @include media-breakpoint-down(md) {
        font-size: 16px;
        margin-bottom: 14px;
      }
      &::before {
        content: '';
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-right: 8px;
        vertical-align: -30%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-color: transparent;
        background-image: url('../../../images/pages/index/hyc-icon-project.png');
        @include media-breakpoint-down(md) {
          width: 20px;
          height: 20px;
          vertical-align: -26%;
        }
      }
    }
    &-desc {
      color: #464958;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 48px;
      @include media-breakpoint-down(md) {
        font-size: 10px;
        line-height: 1.5;
        margin-bottom: 24px;
      }
    }
    &-container {
      width: 100%;
    }

    &-wrapper {
      width: 100%;
      height: 498px;
      @include media-breakpoint-down(md) {
        height: 149px;
      }
    }
    &-slide {
      display: block;
      &-card {
        // padding-top: 32px;
        width: 100%;
        height: 100%;
        position: relative;
        // border: 2px solid #d7d7d7;
        border-radius: 8px;

        &-bgImage {
          width: 100%;
          height: 100%;
          opacity: 0.6;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          border-radius: 8px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-color: transparent;
          background-image: url('../../../images/pages/index/pic_mengban.png');
        }
        @include media-breakpoint-up(lg) {
          position: relative;
          &:hover {
            .index__project-slide-card-project {
              bottom: 0;
            }
          }
        }
        @include media-breakpoint-down(md) {
        }
        &-title {
          color: #3f33a1;
          font-size: 24px;
          line-height: 1;
          text-align: center;
          margin-bottom: 20px;
          a {
            color: #3f33a1;
          }
          @include media-breakpoint-down(md) {
            font-size: 16px;
            margin-bottom: 14px;
          }
          &::before,
          &::after {
            content: '';
            display: inline-block;
            width: 2px;
            height: 16px;
            background-color: #ff5080;
            @include media-breakpoint-down(md) {
              height: 12px;
            }
          }
          &::before {
            margin-right: 20px;
          }
          &::after {
            margin-left: 20px;
          }
          @include media-breakpoint-down(md) {
            &::before {
              margin-right: 10px;
            }
            &::after {
              margin-left: 10px;
            }
          }
        }
        &-text {
          color: #999999;
          font-size: 16px;
          text-align: center;
          margin-bottom: 0;
          &:last-of-type {
            margin-bottom: 32px;
          }
          @include media-breakpoint-down(md) {
            font-size: 10px;
            &:last-of-type {
              margin-bottom: 10px;
            }
          }
        }
        &-image {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
        &-project {
          width: 100%;
          background-color: rgba($color: #ffffff, $alpha: 0.9);
          border-radius: 8px;
          padding-top: 20px;
          padding-bottom: 20px;
          @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: -100%;
            @include transition($hyc-index-transition-project);
          }
          @include media-breakpoint-down(md) {
            padding-top: 15px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &-content {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          &-title {
            position: absolute;
            top: 66.5px;
            left: 63px;
            font-size: 48px;
            color: #ffffff;
            z-index: 20;
            @include media-breakpoint-down(md) {
              font-size: 18px;
              top: 37px;
              left: 24px;
            }
          }
          &-more {
            position: absolute;
            top: 286px;
            left: 63px;
            width: 184px;
            height: 64px;
            z-index: 20;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-color: transparent;
            background-image: url('../../../images/pages/index/but_go.png');
            @include media-breakpoint-down(md) {
              width: 80px;
              height: 20px;
              top: 72.5px;
              left: 24px;
              background-image: url('../../../images/pages/index/but_go_mobile.png');
            }
          }
          &-more_us {
            position: absolute;
            top: 286px;
            left: 63px;
            width: 184px;
            height: 64px;
            z-index: 20;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-color: transparent;
            background-image: url('../../../images/pages/index/but_go_us.png');
            @include media-breakpoint-down(md) {
              width: 80px;
              height: 20px;
              top: 102.5px;
              left: 24px;
              background-image: url('../../../images/pages/index/but_go_mobile_us.png');
            }
          }
          &-btns {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80px;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-items: center;
            font-size: 24px;
            @include media-breakpoint-down(md) {
              height: 24px;
              font-size: 10px;
            }
            &-item {
              width: 33.33%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff !important;
              border: solid 1px #ffffff;
              border-bottom: none !important;
              border-left: none !important;

              &-title {
                width: 100%;
                text-align: center;
                @include media-breakpoint-down(md) {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
            &-item:last-child {
              border-right: none !important;
            }
            &-item:hover {
              background-color: rgba(255, 80, 128, 0.5);
            }
          }
        }
        &-link {
          display: block;
          width: 200px;
          color: #ffffff;
          background-color: #fb7b9e;
          border-radius: 28px;
          font-size: 16px;
          line-height: 0.6;
          text-align: center;
          text-decoration: none;
          padding-top: 8px;
          padding-bottom: 8px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 6px;
          @include media-breakpoint-down(md) {
            width: 300px;
            font-size: 14px;
            padding-top: 6px;
            padding-bottom: 6px;
            margin-bottom: 15px;
          }
          &:hover,
          &:active {
            color: #ffffff;
            text-decoration: none;
          }
        }
        &-more {
          display: block;
          color: #666666;
          font-size: 16px;
          line-height: 1;
          text-align: center;
          text-decoration: none;
          margin-bottom: 0;
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }
          &:hover,
          &:active {
            color: #666666;
            text-decoration: none;
          }
        }
        &.english {
          display: block;
          text-decoration: none;
          &:hover,
          &:active {
            text-decoration: none;
          }
          .index__project-slide-card-title {
            &::before,
            &::after {
              content: '';
              display: none;
            }
          }
        }
      }
    }
    &-swiper-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 29px;
      div {
        cursor: pointer;
      }
      .swiper-button-prev {
        width: 40px;
        height: 40px;
        margin-right: 56px;
        background: url('../../../images/pages/index/swiper_left_btn_reversal.png')
          no-repeat top left;
        background-size: 40px 40px;
      }
      .swiper-button-next {
        width: 40px;
        height: 40px;
        background: url('../../../images/pages/index/swiper_right_btn_reversal.png')
          no-repeat top left;
        background-size: 40px 40px;
      }
      .swiper-button-prev-disable {
        width: 40px;
        height: 40px;
        margin-right: 56px;
        background: url('../../../images/pages/index/swiper_left_btn_dark.png')
          no-repeat top left;
        background-size: 40px 40px !important;
      }
      .swiper-button-next-disable {
        width: 40px;
        height: 40px;
        background: url('../../../images/pages/index/swiper_right_btn_dark.png')
          no-repeat top left;
        background-size: 40px 40px !important;
      }
    }
    &-pagination {
      display: flex;
      justify-content: center;
      .swiper-pagination-bullet {
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: #fbfbfb;
        border-radius: 100%;
        margin-top: 16px;
        &:focus {
          outline: none;
        }
        &:not(:last-of-type) {
          margin-right: 8px;
        }
        &.swiper-pagination-bullet-active {
          background-color: #3f33a1;
        }
      }
    }
  }
  &__media {
    @media (min-width: 75rem) {
      .container {
        max-width: 88.24vw !important;
      }
    }
    padding-top: 61px;
    padding-bottom: 58px;
    background-image: url('../../../images/pages/index/hyc-video-and-news-background.png');
    background-color: #ffffff;
    background-size: auto 100%;
    background-repeat: repeat-y;
    background-position: center;
    @include media-breakpoint-down(md) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &-title {
      margin-bottom: 32px;
      @include media-breakpoint-down(md) {
        margin-bottom: 16px;
      }
      &-text {
        color: #ff5080;
        font-size: 30px;
        line-height: 1;
        font-weight: normal;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
        &::before {
          content: '';
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 8px;
          vertical-align: -30%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-color: transparent;
          @include media-breakpoint-down(md) {
            width: 20px;
            height: 20px;
            vertical-align: -26%;
          }
        }
      }
      &-link {
        color: #3f33a1;
        font-size: 16px;
        line-height: 1;
        text-decoration: none;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
        &:hover,
        &:active {
          color: #3f33a1;
          text-decoration: none;
        }
      }
    }
    &-video {
      width: calc((100% - 50px) / 2);
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 24px;
      }
      &-title {
        &-text {
          &::before {
            background-image: url('../../../images/pages/index/hyc-icon-video.png');
          }
        }
      }
      &-content {
        width: 100%;
        &-figure {
          width: 100%;
          height: auto;
          display: block;
          position: relative;
          margin-bottom: 0;
        }
        &-title {
          margin-top: 31px;
          font-size: 32px;
          color: white;
          @include media-breakpoint-down(md) {
            font-size: 16px !important;
            margin-top: 10px !important;
          }
        }
        &-title_us {
          margin-top: 31px;
          font-size: 32px;
          color: #3f33a1;
          @include media-breakpoint-down(md) {
            font-size: 16px !important;
            margin-top: 10px !important;
          }
        }
        &-player {
          width: 100%;
          height: auto;
          display: block;
          background-color: #000000;
        }
        &-controls {
          display: flex;
          background-color: rgb(233, 233, 233, 0.6);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          &-play {
            width: 96px;
            height: 96px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../../../images/pages/video/icon-play.png');
            background-color: transparent;
            -webkit-appearance: none;
            border: none;
            padding: 0;
            @include media-breakpoint-down(md) {
              width: 60px;
              height: 60px;
            }
            &:focus {
              outline: none;
              border: none;
            }
          }
        }
      }
    }
    &-news {
      width: 100%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      &-title {
        &-text {
          &::before {
            background-image: url('../../../images/pages/index/hyc-icon-news.png');
          }
        }
      }
      &-content {
        width: 100%;
        &-wrapper {
          width: 100%;
          height: 324px;
          @include media-breakpoint-down(md) {
            height: 97px;
          }
        }
        &-pagination {
          display: flex;
          justify-content: center;
          .swiper-pagination-bullet {
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: #fbfbfb;
            border-radius: 100%;
            margin-top: 16px;
            &:focus {
              outline: none;
            }
            &:not(:last-of-type) {
              margin-right: 8px;
            }
            &.swiper-pagination-bullet-active {
              background-color: #3f33a1;
            }
          }
        }
        &-item {
          align-items: flex-start;
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
        &-card {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 24px;
          @include media-breakpoint-down(md) {
            border-radius: 8px;
            // width: 46vw !important;
            // margin-right: 10px !important;
          }
          &-content {
            position: absolute;
            border-radius: 24px;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            @include media-breakpoint-down(md) {
              border-radius: 8px;
            }
            &-title {
              width: 100%;
              padding-left: 30px;
              box-sizing: border-box;
              position: absolute;
              bottom: 19px;
              line-height: 35px;
              font-size: 32px;
              color: #ffffff;
              display: flex;
              align-items: center;
              @include media-breakpoint-down(md) {
                font-size: 10px;
                bottom: 6px;
                padding-left: 10px;
              }

              .left {
                width: 80%;
                height: 70px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                @include media-breakpoint-down(md) {
                  height: 43px;
                }
              }
              .right {
                width: 20%;
                height: 70px;
                line-height: 70px;
                font-size: 50px;
                text-align: center;
                @include media-breakpoint-down(md) {
                  height: 43px;
                  font-size: 20px;
                }
              }
            }
          }
        }
        &-image {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 24px;
          flex-shrink: 0;
          @include media-breakpoint-down(md) {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        &-title {
          display: block;
          width: 100%;
          font-size: 20px;
          line-height: 1;
          font-weight: bold;
          padding-top: 6px;
          @include media-breakpoint-down(md) {
            font-size: 14px;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        &-text {
          color: #cccccc;
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 0;
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }
        }
        &-link {
          flex: 1;
          color: inherit;
          text-decoration: none;
          min-width: 0;
          display: block;
          &:hover,
          &:active {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }
  &__announce {
    @media (min-width: 75rem) {
      .container {
        max-width: 88.24vw !important;
      }
    }
    padding-top: 61px;
    padding-bottom: 58px;
    background-image: url('../../../images/pages/index/hyc-announce-background.png');
    background-color: #ffffff;
    background-size: auto 100%;
    background-repeat: repeat-y;
    background-position: center;
    @include media-breakpoint-down(md) {
      padding-top: 40px;
      padding-bottom: 22px;
    }
    &-content {
      width: calc(100% * 0.4733);
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      &-title {
        color: #ffffff;
        font-size: 30px;
        line-height: 1;
        font-weight: normal;
        margin-bottom: 32px;
        @include media-breakpoint-down(md) {
          font-size: 16px;
          margin-bottom: 22px;
        }
        &::before {
          content: '';
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 8px;
          vertical-align: -30%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-color: transparent;
          background-image: url('../../../images/pages/index/hyc-icon-announce.png');
          @include media-breakpoint-down(md) {
            width: 20px;
            height: 20px;
            vertical-align: -26%;
          }
        }
      }
      &-labels {
        display: flex;
        margin-bottom: 16px;
      }
      &-label {
        width: 100%;
        display: inline-block;
        position: relative;
        &::after {
          content: '';
          display: inline-block;
          border-right: 1px solid #eeeeee;
          padding-top: 10px;
          padding-bottom: 10px;
          position: absolute;
          top: 12px;
          @include media-breakpoint-down(md) {
            top: 4px;
          }
        }
        &:last-of-type {
          &::after {
            display: none;
          }
        }
        label {
          width: calc(100% - 40px);
          color: #ffffff;
          font-size: 20px;
          text-align: center;
          line-height: 1;
          padding-top: 12px;
          padding-bottom: 12px;
          margin-bottom: 0;
          margin-left: 20px;
          margin-right: 20px;
          cursor: pointer;
          position: relative;
          @include media-breakpoint-down(md) {
            width: calc(100% - 32px);
            font-size: 14px;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-left: 16px;
            margin-right: 16px;
          }
        }
        input:focus + label,
        input:checked + label {
          color: #3f33a1;
          background-color: #ffffff;
          &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #ffffff;
            position: absolute;
            top: 96%;
            left: calc(50% - 10px);
          }
        }
      }
      &-tabs {
        width: 100%;
      }
      &-tab {
        display: none;
        &-wrapper {
          list-style: none;
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 0;
          @include media-breakpoint-down(md) {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
        &-item {
          display: flex;
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
          line-height: 1;
          padding-top: 8px;
          padding-bottom: 8px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          justify-content: space-between;
          cursor: pointer;
          @include media-breakpoint-down(md) {
            font-size: 12px;
            padding-top: 5px;
            padding-bottom: 5px;
          }
          &:hover {
            color: #ffffff;
          }
          &-date {
            margin-left: auto;
            margin-right: 0;
          }
          &-title,
          &-date {
            display: inline-block;
          }
        }
      }
      &-more {
        display: block;
        color: #ffffff;
        font-size: 16px;
        line-height: 1;
        text-align: right;
        text-decoration: none;
        margin-top: 24px;
        margin-right: 20px;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          font-size: 12px;
          margin-right: 16px;
        }
        &:hover,
        &:active {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    &-image {
      width: calc(100% * 0.4733);
      height: auto;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &-video {
      width: calc(100% * 0.4733);
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .index__media-video {
        width: 100%;
        @include media-breakpoint-down(md) {
          width: 100%;
          margin-bottom: 24px;
        }
        &-title {
          &-text {
            color: #ffffff !important;
            &::before {
              background-image: url('../../../images/pages/index/video_icon.png');
            }
            @include media-breakpoint-down(md) {
              color: #ffffff !important;
            }
          }
          &-link {
            color: #ffffff !important;
            @include media-breakpoint-down(md) {
              color: #3f33a1 !important;
            }
          }
        }
        &-content {
          width: 100%;
          &-figure {
            width: 100%;
            height: auto;
            display: block;
            position: relative;
            margin-bottom: 0;
          }
          &-title {
            // position: absolute;
            // top: 5px;
            // left: 15px;
            margin-top: 31px;
            font-size: 32px;
            color: white;
          }
          &-player {
            width: 100%;
            height: auto;
            display: block;
            background-color: #000000;
          }
          &-controls {
            display: flex;
            background-color: rgb(233, 233, 233, 0.6);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            &-play {
              width: 96px;
              height: 96px;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url('../../../images/pages/video/icon-play.png');
              background-color: transparent;
              -webkit-appearance: none;
              border: none;
              padding: 0;
              @include media-breakpoint-down(md) {
                width: 60px;
                height: 60px;
              }
              &:focus {
                outline: none;
                border: none;
              }
            }
          }
        }
      }
    }
  }
  &__donate {
    @media (min-width: 75rem) {
      .container {
        max-width: 88.24vw !important;
      }
    }
    padding-top: 40px;
    padding-bottom: 50px;
    background-image: url('../../../images/pages/index/hyc-donate-background.png');
    background-color: #ffffff;
    background-size: auto 100%;
    background-repeat: repeat-y;
    background-position: center;
    @include media-breakpoint-down(md) {
      padding-top: 20px;
      padding-bottom: 26px;
    }
    &-wrapper {
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 40px;
      padding-bottom: 40px;
      background-color: #ffffff;
      box-shadow: 0px 3px 9px 1px rgba(35, 24, 21, 0.15);
      border-radius: 10px;
      @include media-breakpoint-down(md) {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 24px;
        padding-bottom: 28px;
      }
    }
    &-title {
      color: #464958;
      font-size: 20px;
      line-height: 1;
      font-weight: normal;
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        font-size: 16px;
        margin-bottom: 0;
      }
      &::before {
        content: '';
        display: inline-block;
        width: 48px;
        height: 48px;
        margin-right: 8px;
        vertical-align: -30%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-color: transparent;
        @include media-breakpoint-down(md) {
          width: 20px;
          height: 20px;
        }
      }
    }
    &-info {
      margin-right: 40px;
      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: 22px;
      }
      &-title {
        &::before {
          background-image: url('../../../images/pages/index/hyc-icon-donate.png');
        }
      }
      &-container {
        width: 380px;
        height: 60px;
        @include media-breakpoint-down(md) {
          width: 100%;
          height: 30px;
          margin-top: 10px;
        }
      }
      &-slide {
        border-right: 1px solid #dddddd;
        @include media-breakpoint-down(md) {
          border: none;
        }
        &-link {
          display: block;
          height: 60px;
          color: #ff5080;
          font-size: 20px;
          line-height: 60px;
          text-decoration: none;
          @include media-breakpoint-down(md) {
            height: 30px;
            font-size: 16px;
            line-height: 30px;
          }
          &:hover,
          &:active {
            color: #ff5080;
            text-decoration: none;
          }
          span {
            font-size: 16px;
            &:not(:last-of-type) {
              margin-right: 8px;
            }
          }
        }
      }
    }
    &-search {
      flex: 1;
      &-title {
        &::before {
          background-image: url('../../../images/pages/index/hyc-icon-hert.png');
        }
      }
      &-form {
        display: flex;
        @include media-breakpoint-down(md) {
          margin-top: 10px;
        }
        &-input {
          flex: 1;
          height: 60px;
          border: 1px solid #cccccc;
          padding-left: 20px;
          padding-right: 20px;
          @include media-breakpoint-down(md) {
            height: 32px;
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
          }
          &:focus {
            outline: none;
            outline-offset: 0;
          }
        }
        &-button {
          width: 138px;
          height: 60px;
          color: #ffffff;
          background-color: #3f33a1;
          border: 1px solid #3f33a1;
          font-size: 20px;
          line-height: 1;
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          @include media-breakpoint-down(md) {
            width: 72px;
            height: 32px;
            font-size: 14px;
            padding-top: 9px;
            padding-bottom: 9px;
          }
          &:focus {
            outline: none;
            outline-offset: 0;
          }
        }
      }
    }
  }
}

