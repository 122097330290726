.search__page {
  background-image: url('../../../images/pages/common/hyc-background.png');
  background-size: $hyc-information-background-size;
  background-repeat: $hyc-information-background-repeat;
  margin-bottom: 80px;
  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
    .oc-component__pagination {
      &-link {
        font-size: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 30px;
      }
      &-first,
      &-last {
        display: none;
      }
      &-first,
      &-last,
      &-prev,
      &-next {
        min-width: 64px;
      }
    }
  }
  &-breadcrumbs {
    border-bottom: 2px solid #E9E9E9;
  }
  &-content {
    padding-top: 20px;
    &-item {
      align-items: flex-start;
      margin-bottom: 20px;
      &-image {
        display: block;
        width: 300px;
        height: auto;
        flex-shrink: 0;
        margin-right: 20px;
        @include media-breakpoint-down(md) {
          width: 140px;
          margin-right: 10px;
        }
      }
      &-content {
        flex: 1;
        min-width: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        @include media-breakpoint-down(md) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      &-title {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 24px;
        @include media-breakpoint-down(md) {
          font-size: 16px;
          margin-bottom: 10px;
          /* 限制一行 */
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &-desc {
        color: #797A90;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 12px;
        flex: 1;
        @include media-breakpoint-down(md) {
          font-size: 12px;
          margin-bottom: 0;
          /* 限制两行 */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &-link {
        display: block;
        background-color: #FF5080;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        line-height: 1;
        padding-top: 14px;
        padding-bottom: 14px;
        margin-left: auto;
        width: 124px;
        @include media-breakpoint-down(md) {
          font-size: 10px;
          line-height: 1;
          padding-top: 6px;
          padding-bottom: 6px;
          width: 64px;
        }
        &:hover {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
}
