.information__page {
  background-image: url('../../../images/pages/common/hyc-background.png');
  background-size: $hyc-information-background-size;
  background-repeat: $hyc-information-background-repeat;
  margin-bottom: 60px;
  &-title {
    color: $hyc-color-information-title;
    font-size: $hyc-fontsize-information-heading;
    text-align: $hyc-information-title-text-align;
    font-weight: $hyc-information-title-font-weight;
    margin-bottom: $hyc-information-title-margin;

    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 9px;
      background-size: 14px 9px;
      background-repeat: no-repeat;
      background-image: url('../../../images/pages/common/icon-down.png');
      position: absolute;
      top: 100%;
      left: calc(50% - 7px);
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-down(md) {
  .information__page {
    margin-bottom: 40px;
    &-title {
      font-size: $hyc-fontsize-information-heading - 6px; // 24px
      margin-bottom: $hyc-information-title-margin / 2; // 30px
    }
  }
}
