.about__regulate {
  &-content {
    & > p,
    & > h5 {
      line-height: $hyc-about-regulate-content-line-height;
    }
    & > p > span {
      display: block;
      line-height: normal;
      text-indent: 4em;
    }
    & > ol {
      padding-left: 4em;
    }
  }
}

@include media-breakpoint-down(md) {
  .about__regulate {
    &-title {
      margin-bottom: $hyc-about-title-margin / 3 * 2; // 40px
    }
    &-content {
      & > p {
        line-height: $hyc-about-regulate-content-line-height / 2 + 8px; // 24px
        & > span {
          text-indent: 2em;
          line-height: $hyc-about-regulate-content-line-height / 2 + 8px;
        }
      }
      & > ol {
        padding-left: 2em;
        & > li.list-fix {
          list-style-type: none;
          &::before {
            content: '' !important;
          }
          & > p::before {
            content: '三、';
          }
        }
      }
    }
  }
}
