.about__supervisors {
  &-content {
    max-width: $hyc-about-content-max-width;
  }
  // 理事介绍详情页
  &-detail {
    margin-bottom: 40px;
    &-image {
      width: 230px;
      height: auto;
      flex-shrink: 0;
    }
    &-name {
      color: $hyc-color-about-supervisors-name;
      font-size: 30px;
      &::after {
        content: '';
        display: block;
        width: 2em;
        height: 2px;
        background-color: #F8901F;
        position: relative;
        left: 0;
        top: 100%;
      }
    }
    &-gender {
      color: $hyc-color-about-supervisors-gender;
      font-size: 20px;
    }
    &-title {
      font-size: 20px;
    }
    &-desc {
      line-height: 40px;
      text-indent: 2em;
    }
    &-title,
    &-desc {
      color: $hyc-color-about-supervisors-content;
    }
  }
}

@include media-breakpoint-down(md) {
  .about__supervisors {
    &-detail {
      &-image {
        width: 90px;
        height: 120px;
      }
      &-name {
        font-size: 16px;
      }
      &-desc {
        line-height: 24px;
      }
      &-gender,
      &-title,
      &-desc {
        font-size: 14px;
      }
    }
  }
}
