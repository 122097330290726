.partner__page {
  background-image: url('../../../images/pages/common/hyc-background.png');
  background-size: $hyc-partner-background-size;
  background-repeat: $hyc-partner-background-repeat;
  margin-top: $hyc-partner-page-margin;
  margin-bottom: $hyc-partner-page-margin * 1.5;
  &-title {
    color: $hyc-color-partner-title;
    font-size: $hyc-fontsize-partner-heading;
    text-align: $hyc-partner-title-text-align;
    font-weight: $hyc-partner-title-font-weight;
    margin-bottom: $hyc-partner-title-margin;

    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 9px;
      background-size: 14px 9px;
      background-repeat: no-repeat;
      background-image: url('../../../images/pages/common/icon-down.png');
      position: absolute;
      top: 100%;
      left: calc(50% - 7px);
      margin-top: 10px;
    }
  }
  &-content {
    &-labels {
      display: flex;
    }
    &-label {
      label {
        color: #888888;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 0;
        line-height: 1;
        cursor: pointer;
      }
      input:focus + label,
      input:checked + label {
        color: #FF5080;
        font-weight: bold;
      }
      &:not(:last-of-type) {
        margin-right: 32px;
      }
    }
    &-tabs {
      margin-top: 32px;
    }
    &-tab {
      display: none;
      &-item {
        width: calc((100% - (20px * 4)) / 5);
        border: 1px solid #eeeeee;
        margin-bottom: 20px;
        margin-right: 20px;
        // &:nth-of-type(5n) {
        //   margin-right: 0;
        // }
        &-link {
          display: block;
          margin: 0;
          text-decoration: none;
        }
        &-image {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) { 
  .partner__page {
    &-content {
      &-tab {
        &-item {
          &:nth-of-type(5n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .partner__page {
    margin-top: $hyc-partner-page-margin - 16px; // 32px
    margin-bottom: $hyc-partner-page-margin - 16px;
    &-title {
      margin-bottom: $hyc-partner-title-margin / 2; // 30px
    }
    &-content {
      &-label {
        label {
          padding-left: 8px;
          padding-right: 8px;
        }
        &:not(:last-of-type) {
          margin-right: 16px;
        }
      }
      &-tabs {
        margin-top: 24px;
      }
      &-tab {
        &-item {
          width: calc((100% - 20px) / 2);
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
