.hyc-component__team-card {
  width: 460px;
  border:2px solid $hyc-color-about-supervisors-border;
  background-color: #ffffff;
  box-shadow: 0px 2px 0px 0px #EBEEF0;
  margin-bottom: $hyc-about-supervisors-card-margin;
  &-wrapper {
    height: 100%;
    align-items: flex-start;
  }
  &-content {
    height: 100%;
    flex: 1;
  }
  &-image {
    width: 120px;
    height: auto;
    flex-shrink: 0;
  }
  &-name {
    color: $hyc-color-about-supervisors-name;
    &::after {
      content: '';
      display: block;
      width: 2em;
      height: 2px;
      background-color: #F8901F;
      position: relative;
      left: 0;
      top: 100%;
    }
  }
  &-gender {
    color: $hyc-color-about-supervisors-gender;
  }
  &-title,
  &-excerpt {
    color: $hyc-color-about-supervisors-content;
    line-height: $hyc-about-supervisors-content-line-height;
    margin-bottom: 0;
  }
  &-link {
    color: $hyc-color-about-supervisors-link;
  }
  &-gender,
  &-title,
  &-excerpt,
  &-link {
    font-size: $hyc-fontsize-about-supervisors;
  }
}

@include media-breakpoint-down(md) {
  .hyc-component__team-card {
    width: 100%;
    &-image {
      width: 90px;
      align-self: baseline;
    }
    &-name {
      margin-bottom: 10px;
    }
    &-title,
    &-excerpt {
      line-height: $hyc-about-supervisors-content-line-height - 4px; // 20px
    }
  }
}
